export type AllValues<T> = T extends object
  ? T[keyof T] extends infer U
    ? U extends object
      ? AllValues<U> | Exclude<T[keyof T], object>
      : T[keyof T]
    : never
  : never;

export enum PermissionKeyEnum {
  Ri = 'ri-permissions',
  Core = 'core-permissions',
}
