import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { ReactElement } from 'react';
import { isRiDashboardEnabled } from '@/features/RentersInsurance/utils';
import { useRiPermissionsStore } from '@/stores/permissions.store';

interface Props {
  open: boolean;
  text?: string;
  displayText?: ReactElement;
  action: () => void;
  icon?: JSX.Element;
  ariaLabel?: string;
  selected?: boolean;
  disabled?: boolean;
}

export const SideMenuItem = ({
  open,
  text,
  displayText,
  action,
  icon,
  ariaLabel,
  selected,
  disabled,
}: Props) => {
  const riPermissions = useRiPermissionsStore((state) => state.riPermissions);
  const enabled = isRiDashboardEnabled(riPermissions);

  const listItemMdWidth = enabled ? '284px' : '250px';
  const mobileMenuListItemWidth = enabled ? '180px' : '160px';

  return (
    <ListItem
      disablePadding
      sx={{
        width: { xs: '100%', md: open ? listItemMdWidth : '60px' },
        transition: 'width 0.3s linear',
      }}
    >
      <Tooltip arrow title={text} disableHoverListener={open || !text} placement="right">
        <ListItemButton
          onClick={action}
          className={open ? 'list-item-button-open' : 'list-item-button-close'}
          disabled={disabled}
          sx={{
            justifyContent: text || !open ? 'flex-start' : 'flex-end',
            height: '50px',
            backgroundColor: selected ? 'grey.50' : undefined,
            [':hover']: {
              backgroundColor: 'secondary.light',
            },
          }}
          aria-label={ariaLabel ?? text}
        >
          <ListItemIcon
            sx={{
              justifyContent: text || !open ? 'flex-start' : 'flex-end',
            }}
          >
            {icon}
          </ListItemIcon>
          {text ? (
            <ListItemText
              primary={displayText ?? text}
              primaryTypographyProps={{ color: disabled ? 'text.disabled' : undefined }}
              sx={{
                opacity: open ? '1' : '0',
                width: open ? mobileMenuListItemWidth : '0',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                transition: 'all 0.3s linear',
              }}
            />
          ) : null}
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );
};
