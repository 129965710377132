import { RiPermissions, CorePermissions } from '@/constants/permissions.constant';
import { AllValues } from '@/utils/types/types.util';

export function hasRiPermission(
  chosenPermission: AllValues<typeof RiPermissions>,
  riPermissions: AllValues<typeof RiPermissions>[],
) {
  return riPermissions.includes(chosenPermission);
}

export function hasCorePermission(
  chosenPermission: AllValues<typeof CorePermissions>,
  corePermissions: AllValues<typeof CorePermissions>[],
) {
  return corePermissions.includes(chosenPermission);
}
