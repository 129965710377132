import GlobalRouter from './routes/Router';
import SiteWrapper from './appWrapper/SiteWrapper';
import featureflagStore from './stores/featureflagStore';
import { trackOnClick } from './utils/trackOnClick';
import { updateUrlTitle } from './utils/updateUrlTitle';
import useABTestStore from './stores/abTestStore';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function App() {
  const location = useLocation();
  featureflagStore.getState().useFetchFeatureFlags();
  const { loaded } = useABTestStore();
  useABTestStore.getState().useFetchABTestsValues();

  useEffect(() => {
    if (loaded) {
      updateUrlTitle();
    }
  }, [location.pathname, loaded]);

  return (
    <div id="shell_container" onClick={(event) => trackOnClick(event)}>
      <SiteWrapper>
        <GlobalRouter />
      </SiteWrapper>
    </div>
  );
}
