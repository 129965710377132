import { isRiDashboardEnabled } from '../utils';
import { ReactNode } from 'react';
import { useRiPermissionsStore } from '@/stores/permissions.store';

type RiVisibilityGuardProps = {
  children: ReactNode;
  /** The fallback component to render if the Renters Insurance dashboard is not enabled */
  fallback?: ReactNode;
};

/**
 * A guard component that conditionally renders its children based on the visibility of the Renters Insurance dashboard.
 * If the dashboard is enabled, it renders the children. Otherwise, it renders the fallback component, by default `null`.
 */
export function RiVisibilityGuard({ children, fallback = null }: RiVisibilityGuardProps) {
  const riPermissions = useRiPermissionsStore((state) => state.riPermissions);
  const isEnabled = isRiDashboardEnabled(riPermissions);

  if (isEnabled) {
    return <>{children}</>;
  }

  return fallback;
}
