import { routes } from '@/constants/Constants';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { CorePermissions } from '@/constants/permissions.constant';
import { useCorePermissionsStore } from '@/stores/permissions.store';
import { hasCorePermission } from '@/utils/permissions.util';

type CoreRedirectGuardProps = {
  children: ReactNode;
  redirectUrl?: string;
};

/**
 * A guard component that redirects to a specified URL if the user does not have a permission to view Core product tiles
 * @param props.redirectUrl - The URL to redirect to if unauthorized to access Core product tiles. Defaults to the home route.
 */
export function CoreRedirectGuard(props: CoreRedirectGuardProps) {
  const { children, redirectUrl } = props;
  const corePermissions = useCorePermissionsStore((state) => state.corePermissions);
  const isCoreEnabled = hasCorePermission(CorePermissions.Tiles.FullRead, corePermissions);

  const usedRedirectUrl = redirectUrl ?? routes.home;

  if (!isCoreEnabled) {
    return <Navigate to={usedRedirectUrl} replace />;
  }

  return <>{children}</>;
}
