import MUILink, { LinkProps as MUILinkProps } from '@mui/material/Link';

import { Link as RouterLink } from 'react-router-dom';
import { createUrlWithUTM } from '@/utils/linkUtils';

type LinkPropsVariants =
  | { to: string }
  | { href: string }
  | { onClick: React.MouseEventHandler<HTMLAnchorElement> };
type LinkProps = Omit<MUILinkProps, 'href' | 'onClick'> & LinkPropsVariants;

export const Link = (props: LinkProps) => {
  if ('onClick' in props) {
    return <MUILink {...props} />;
  }

  if ('to' in props) {
    try {
      const to = createUrlWithUTM(props.to);

      return <MUILink {...props} component={RouterLink} to={to.toString()} />;
    } catch (_e) {
      // Url is relative
      return <MUILink {...props} component={RouterLink} />;
    }
  }

  try {
    const href = createUrlWithUTM(props?.href);

    return <MUILink {...props} href={href.toString()} />;
  } catch (_e) {
    // Url is relative
    return <MUILink {...props} />;
  }
};
