import { ReactNode } from 'react';
import { CorePermissions } from '@/constants/permissions.constant';
import { useCorePermissionsStore } from '@/stores/permissions.store';
import { hasCorePermission } from '@/utils/permissions.util';

type CoreVisibilityGuardProps = {
  children: ReactNode;
};

/**
 * A guard component that conditionally renders its children based on the visibility of the Core product tiles.
 * If the core permission is enabled, it renders the children. Otherwise, it renders nothing.
 */
export function CoreVisibilityGuard({ children }: CoreVisibilityGuardProps) {
  const corePermissions = useCorePermissionsStore((state) => state.corePermissions);
  const isCoreEnabled = hasCorePermission(CorePermissions.Tiles.FullRead, corePermissions);

  if (isCoreEnabled) {
    return <>{children}</>;
  }

  return null;
}
