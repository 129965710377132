import React, { ReactNode, useEffect } from 'react';
import { useCorePermissions } from '@/utils/apiRestCalls/core-permissions.api';
import { useCorePermissionsStore } from '@/stores/permissions.store';
import { savePermissionsToStorage } from '@/utils/storage/permissions.storage';
import { PermissionKeyEnum } from '@/utils/types/types.util';
import useFeatureFlagStore from '@/stores/featureflagStore';

type Props = {
  children: ReactNode;
};

export function CoreWrapper({ children }: Props) {
  const { featureFlags } = useFeatureFlagStore();

  const setCorePermissions = useCorePermissionsStore((state) => state.setCorePermissions);
  const { data: corePermissionsResponse, isFetched } = useCorePermissions(
    featureFlags['tiger-1497_ri_enrolled'],
  );

  useEffect(() => {
    if (isFetched) {
      const corePermissions = corePermissionsResponse?.corePermissionStatus || [];
      setCorePermissions(corePermissions);
      savePermissionsToStorage(PermissionKeyEnum.Core, corePermissions);
    }
  }, [isFetched]);

  return <>{children}</>;
}
