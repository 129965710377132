import { apiUri } from '@/constants/Constants';
import { client } from '@/utils/axios/axiosClient';
import { useQuery } from '@tanstack/react-query';
import { AllValues } from '@/utils/types/types.util';
import { RiPermissions } from '@/constants/permissions.constant';
import useAuthStore from '@/stores/authStore';

type GetRiApplicationPermissionsResponse = {
  result: AllValues<typeof RiPermissions>[];
};

export async function getRiApplicationPermissions(): Promise<GetRiApplicationPermissionsResponse> {
  const requestUrl = apiUri.getRiApplicationPermissions;
  const response = await client.get(requestUrl, {
    withCredentials: true,
  });

  return response.data;
}

export function useRiApplicationPermissions() {
  const accessToken = useAuthStore((state) => state.accessToken);

  const data = useQuery({
    queryKey: ['getRiPermissions'],
    queryFn: () => getRiApplicationPermissions(),
    enabled: Boolean(accessToken) && accessToken !== 'DefaultToken',
  });

  return data;
}
