import React, { ReactNode, useEffect } from 'react';
import { useRiApplicationPermissions } from '@/utils/apiRestCalls/ri-application-permissions.api';
import { useRiPermissionsStore } from '@/stores/permissions.store';
import { savePermissionsToStorage } from '@/utils/storage/permissions.storage';
import { PermissionKeyEnum } from '@/utils/types/types.util';

type Props = {
  children: ReactNode;
};

export function RiWrapper({ children }: Props) {
  const setRiPermissions = useRiPermissionsStore((state) => state.setRiPermissions);
  const { data: riPermissionsResponse, isFetched } = useRiApplicationPermissions();

  useEffect(() => {
    if (isFetched) {
      const riPermissions = riPermissionsResponse?.result || [];
      setRiPermissions(riPermissions);

      savePermissionsToStorage(PermissionKeyEnum.Ri, riPermissions);
    }
  }, [isFetched]);

  return <>{children}</>;
}
