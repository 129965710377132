import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navigate, useNavigate } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';

import Loader from './Loader';
import MyLandbot from '../components/Landbot';
import { PageWrapper } from './PageWrapper';
import { Userpilot } from 'userpilot';
import { cachedSessionStorage } from '../utils/cachedSessionStorage';
import {
  checkIfUserCanEnrollProperties,
  shouldUserStayInEnrollProperty,
} from '../utils/commonUtils';
import getEnv from '../env';
import getUser from '../utils/apiRestCalls/getUser';
import { identifyUser } from '../utils/analytics';
import { routes } from '../constants/Constants';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useUrlChecker } from '../hooks/useUrlChecker';

interface Props {
  children: JSX.Element[] | JSX.Element;
}

export default function SiteWrapper({ children }: Props) {
  const [searchParams] = useSearchParams();
  const locationPath = useLocation();
  const navigate = useNavigate();
  const { isPublic } = useUrlChecker();

  useEffect(() => {
    cachedSessionStorage.cacheStorage();
    // Take all query params for the future.
    cachedSessionStorage.storeUTM(searchParams);
  }, [searchParams]);

  const {
    data: user,
    isLoading,
    status,
    error,
  } = useQuery({
    queryKey: ['user'],
    queryFn: () => getUser(),
    staleTime: Infinity,
    enabled: !isPublic,
  });

  useEffect(() => {
    if (status === 'success') {
      identifyUser(user);
      Userpilot.identify(user.id || '', {
        name: user.firstName || '',
        email: user.email || '',
        LLType: user.LLType,
        canEnrollProperties: user.canEnrollProperties,
        newDealsDashboardAccess: user.newDealsDashboardAccess,
        env: getEnv().app_env,
      });

      setTimeout(() => {
        if (user?.role !== 'landlord') return navigate(routes.limitation);

        if (shouldUserStayInEnrollProperty(user)) {
          return navigate(routes.propertyEnrollmentForm);
        }
      }, 0);
    }
  }, [navigate, status, user]);

  useEffect(() => {
    if (user) {
      Userpilot.reload();
    }
  }, [locationPath.pathname, user]);

  if (!isPublic && isLoading) return <Loader />;
  if (error) return <Navigate to={routes.error500} replace={true} />;

  return (
    <HelmetProvider>
      <Helmet>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src={`//js.hs-scripts.com/${getEnv().hubspot_portal_id}.js`}
        ></script>
      </Helmet>
      {isPublic && !location.hostname.includes('localhost') ? (
        <Helmet>
          <script
            id="cookieyes"
            type="text/javascript"
            src="https://cdn-cookieyes.com/client_data/f4c8903f602bc4de68ebe271/script.js"
          ></script>
        </Helmet>
      ) : null}
      {user && checkIfUserCanEnrollProperties(user) && <MyLandbot />}
      <PageWrapper>{children}</PageWrapper>
    </HelmetProvider>
  );
}
