import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { AllValues, PermissionKeyEnum } from '@/utils/types/types.util';
import { RiPermissions, CorePermissions } from '@/constants/permissions.constant';
import { loadPermissionsFromStorage } from '@/utils/storage/permissions.storage';

const useRiPermissionsStore = create(
  devtools<{
    riPermissions: AllValues<typeof RiPermissions>[];
    setRiPermissions: (riPermissions: AllValues<typeof RiPermissions>[]) => void;
  }>((set) => ({
    riPermissions: loadPermissionsFromStorage(PermissionKeyEnum.Ri) as AllValues<
      typeof RiPermissions
    >[],
    setRiPermissions: (riPermissions) => {
      set({ riPermissions });
    },
  })),
);

const useCorePermissionsStore = create(
  devtools<{
    corePermissions: AllValues<typeof CorePermissions>[];
    setCorePermissions: (corePermissions: AllValues<typeof CorePermissions>[]) => void;
  }>((set) => ({
    corePermissions: loadPermissionsFromStorage(PermissionKeyEnum.Core) as AllValues<
      typeof CorePermissions
    >[],
    setCorePermissions: (corePermissions) => {
      set({ corePermissions });
    },
  })),
);

export { useRiPermissionsStore, useCorePermissionsStore };
