import { routes } from '@/constants/Constants';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { isRiDashboardEnabled } from '@/features/RentersInsurance/utils';
import { useRiPermissionsStore } from '@/stores/permissions.store';

type RiRedirectGuardProps = {
  children: ReactNode;
  redirectUrl?: string;
};

/**
 * A guard component that redirects to a specified URL if the user does not have a permission to view Renters Insurance dashboard
 * @param props.redirectUrl - The URL to redirect to if Renters Insurance dashboard is not enabled. Defaults to the home route.
 */
export function RiRedirectGuard(props: RiRedirectGuardProps) {
  const { children, redirectUrl } = props;
  const riPermissions = useRiPermissionsStore((state) => state.riPermissions);
  const isEnabled = isRiDashboardEnabled(riPermissions);

  const usedRedirectUrl = redirectUrl ?? routes.home;

  if (!isEnabled) {
    return <Navigate to={usedRedirectUrl} replace />;
  }

  return <>{children}</>;
}
