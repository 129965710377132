import { AllValues, PermissionKeyEnum } from '@/utils/types/types.util';
import { RiPermissions, CorePermissions } from '@/constants/permissions.constant';

export function loadPermissionsFromStorage(
  permissionKey: PermissionKeyEnum,
): AllValues<typeof RiPermissions | typeof CorePermissions>[] {
  try {
    const jsonItem = localStorage.getItem(permissionKey) ?? JSON.stringify([]);

    return JSON.parse(jsonItem);
  } catch (error) {
    return [];
  }
}

export function savePermissionsToStorage(
  permissionKey: PermissionKeyEnum,
  permissions: AllValues<typeof RiPermissions | typeof CorePermissions>[],
) {
  localStorage.setItem(permissionKey, JSON.stringify(permissions));
}
