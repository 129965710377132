import React from 'react';
import { routes } from '@/constants/Constants';

const EnrollPropertyConfirmation = React.lazy(
  () => import('../features/EnrollProperty/EnrollConfirmation'),
);
const Home = React.lazy(() => import('../features/Home/Home'));
const Property = React.lazy(() => import('../features/EnrollProperty/EnrollPropertyForm/Property'));
const UnitsOwnedEnroll = React.lazy(() => import('../features/PreEnrollment/UnitsOwnedEnroll'));
const RenterInvite = React.lazy(() => import('../features/RenterInvite/RenterInvite'));
const RenterInviteConfirmation = React.lazy(
  () => import('../features/RenterInvite/RenterInviteConfirmation'),
);
const PropertiesTable = React.lazy(() => import('../features/Home/PropertiesTable'));
const AccountUsersList = React.lazy(() => import('../features/AccountUsersList/AccountUsersList'));
const MyProperties = React.lazy(() => import('../features/Home/MyProperties'));
const DealsDashboard = React.lazy(() => import('../features/DealsDashboard/DealsDashboard'));
const DealsDashboardRedirect = React.lazy(() => import('../utils/RedirectComponent'));
const RentersInsurance = React.lazy(() => import('../features/RentersInsurance/RentersInsurance'));
const ClaimsReport = React.lazy(() => import('../features/ClaimsReport/ClaimsReport'));

export const PrivateRoutes = [
  {
    path: routes.home,
    component: Home,
    title: 'TheGuarantors',
  },
  {
    path: routes.myProperties,
    component: MyProperties,
    title: 'Your dashboard | TheGuarantors',
  },
  {
    path: routes.propertiesTable,
    component: PropertiesTable,
    title: 'Your dashboard | TheGuarantors',
  },
  // Renter Invite
  {
    path: routes.renterInvite,
    component: RenterInvite,
    title: 'Invite an applicant | TheGuarantors',
  },
  {
    path: routes.renterInviteConfirmation,
    component: RenterInviteConfirmation,
    title: 'Applicant invited | TheGuarantors',
  },
  // Property Enrollment
  {
    path: routes.enroll,
    component: Property,
    title: 'Enroll your property | TheGuarantors',
  },
  {
    path: routes.propertyEnrollmentForm,
    component: Property,
    title: 'Add a property | TheGuarantors',
  },
  {
    path: routes.unitsOwnedEnroll,
    component: UnitsOwnedEnroll,
    title: 'Complete your enrollment | TheGuarantors',
  },
  {
    path: routes.propertyEnrollConfirm,
    component: EnrollPropertyConfirmation,
    title: 'Property enrolled | TheGuarantors',
  },
  // Account
  {
    path: routes.accountUsersList,
    component: AccountUsersList,
    title: 'Manage your users | TheGuarantors',
  },
  // Deals Dashboard
  {
    path: routes.dealsDashboard,
    component: DealsDashboard,
    title: 'Application Tracker | TheGuarantors',
  },
  // Deals Dashboard Old Route to Redirect
  {
    path: routes.dealsDashboardRedirectLink,
    component: DealsDashboardRedirect,
    title: 'Application Tracker | TheGuarantors',
  },
  // Renters Insurance
  {
    path: routes.rentersInsurance,
    component: RentersInsurance,
    title: 'Renters Insurance | TheGuarantors',
  },
  // Claims Report
  {
    path: routes.claimsReport,
    component: ClaimsReport,
    title: 'Track Claims | TheGuarantors',
  },
].map((route) => {
  return {
    ...route,
    role: 'protected',
  };
});
