import { sizeOfPortfolio } from '../../features/Registration/constants/UnitOptions';
import { UserStatus } from '../../constants/Constants';

export type newUserResponse = {
  statusCode: 200;
  data: {
    legacy: { monolith: { roles: ['landlord'] }; msa: { roles: []; status: 'new' } };
    user: {
      id: '5b49a86f-6290-43d5-9688-35a631777116';
      email: 'chicken.time+72@theguarantors.com';
      firstName: '';
      fullName: 'null null';
      phone: '';
      ownedUnits: null;
      hdyhearAboutUs: null;
      propertyLLC: null;
      status: ['NEW_USER', 'OUTDATED_PROFILE'];
    };
  };
}; // New user response

export type JustCreatedUser = {
  statusCode: 200;
  data: {
    legacy: { monolith: { roles: ['landlord'] }; msa: { roles: []; status: 'new' } };
    user: {
      id: '5b49a86f-6290-43d5-9688-35a631777116';
      email: 'chicken.time+72@theguarantors.com';
      firstName: 'Timo';
      lastName: 'Limo';
      fullName: 'Timo Limo';
      phone: '(122) 322-2111';
      ownedUnits: 12;
      hdyhearAboutUs: 'biggerPockets';
      propertyLLC: '123';
      status: ['NEW_USER'];
    };
  };
};
export type UserWithProperty = {
  statusCode: 200;
  data: {
    legacy: { monolith: { roles: ['landlord'] }; msa: { roles: ['landlord']; status: 'existent' } };
    user: {
      id: '5b49a86f-6290-43d5-9688-35a631777116';
      email: 'chicken.time+72@theguarantors.com';
      firstName: 'Timo';
      lastName: 'Limo';
      fullName: 'Timo Limo';
      phone: '(122) 322-2111';
      ownedUnits: 12;
      hdyhearAboutUs: 'biggerPockets';
      propertyLLC: '123';
      status: ['TMC_ACCEPTED', 'HAS_PROPERTIES'];
    };
  };
};
export type UserResponse = {
  statusCode: number;
  data: {
    user: User;
  };
};

export enum LLTypes {
  SMB = 'SMB',
  Institutional = 'Institutional',
}

export type User = {
  email: string;
  lastName?: string;
  firstName?: string;
  fullName?: string;
  id?: string;
  phone?: string;
  ownedUnits?: number;
  status?: UserStatus;
  propertyLLC?: string;
  hdyhearAboutUs?: string;
  partner?: string;
  role: string;
  sizeOfPortfolio: sizeOfPortfolio;
  sizeOfPortfolioOld: sizeOfPortfolio;
  isAccountAdmin: boolean;
  setPasswordLink?: string;
  isSMB?: boolean;
  LLType?: LLTypes;
  canEnrollProperties?: boolean;
  account: Account;
  foundAln?: {
    source: string;
    companyId: string;
    numberOfProperties: number;
    companyName: string;
  };
  newDealsDashboardAccess: boolean;
  claimsReportAccess: boolean;
};

export type Account = {
  metadata: {
    monolith_id: string;
  };
};

export interface AxiosUserResponse {
  data: UserResponse;
}

export const userStatusFixture: UserStatus = {
  hasProperties: true,
  isNewSelfServiceUser: true,
  isAccountAdmin: false,
  newSMBNotification: 'false',
};

export const userFixture: User = {
  email: 'example.user@theguarantors.com',
  lastName: 'User',
  firstName: 'Example',
  fullName: 'Example User',
  id: '123e4567-e89b-12d3-a456-426614174000',
  phone: '(123) 456-7890',
  ownedUnits: 5,
  status: userStatusFixture,
  propertyLLC: 'Example LLC',
  hdyhearAboutUs: 'Referral',
  partner: 'Partner Name',
  role: 'landlord',
  sizeOfPortfolio: sizeOfPortfolio.elevenToFifty,
  sizeOfPortfolioOld: sizeOfPortfolio.elevenToFifty,
  isAccountAdmin: true,
  setPasswordLink: 'https://example.com/set-password',
  isSMB: true,
  LLType: LLTypes.SMB,
  canEnrollProperties: true,
  account: {
    metadata: {
      monolith_id: 'monolith-123',
    },
  },
  foundAln: {
    source: 'source',
    companyId: 'company-id',
    numberOfProperties: 10,
    companyName: 'Company Name',
  },
  newDealsDashboardAccess: true,
  claimsReportAccess: true,
};
