import { apiUri } from '@/constants/Constants';
import { client } from '@/utils/axios/axiosClient';
import { useQuery } from '@tanstack/react-query';
import { AllValues } from '@/utils/types/types.util';
import { CorePermissions } from '@/constants/permissions.constant';
import useAuthStore from '@/stores/authStore';

type GetCorePermissionsResponse = {
  corePermissionStatus: AllValues<typeof CorePermissions>[];
};

export async function getCorePermissions(): Promise<GetCorePermissionsResponse> {
  const requestUrl = apiUri.getCorePermissions;
  const response = await client.get(requestUrl, {
    withCredentials: true,
  });

  return response.data;
}

export function useCorePermissions(riEnrolledFF: boolean) {
  const accessToken = useAuthStore((state) => state.accessToken);

  const data = useQuery({
    queryKey: ['getCorePermissions'],
    queryFn: () => {
      if (!riEnrolledFF) {
        return {
          corePermissionStatus: ['ll-portal-backend.core.tiles.read'] as AllValues<
            typeof CorePermissions
          >[],
        };
      }
      return getCorePermissions();
    },
    enabled: Boolean(accessToken) && accessToken !== 'DefaultToken',
  });

  return data;
}
