import useFeatureflagStore from '@/stores/featureflagStore';
import { hasRiPermission } from '@/utils/permissions.util';
import { RiPermissions } from '@/constants/permissions.constant';
import { AllValues } from '@/utils/types/types.util';

const isFeatureFlagEnabled = (): boolean => {
  const featureFlags = useFeatureflagStore.getState().featureFlags;
  return featureFlags.enable_new_renters_insurance_dashboard;
};

export const isRiDashboardEnabled = (riPermissions: AllValues<typeof RiPermissions>[]): boolean => {
  const isFFOn = isFeatureFlagEnabled();
  const isUserGroupAllowed = hasRiPermission(RiPermissions.RiApplications.FullRead, riPermissions);

  return isFFOn && isUserGroupAllowed;
};
