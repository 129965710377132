import './index.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from '@mui/material/styles';
import { Userpilot } from 'userpilot';
import { createRoot } from 'react-dom/client';
import { datadogRum } from '@datadog/browser-rum';
import getEnv from './env';
import { materialTheme } from './theme/theme';
import packageJson from '../package.json';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const { version } = packageJson;
rootElement.setAttribute('version', version.substring(1));
const root = createRoot(rootElement);
const queryClient = new QueryClient();

datadogRum.init({
  applicationId: getEnv().react_app_dd_app_id,
  clientToken: getEnv().react_app_dd_client_id,
  site: 'datadoghq.com',
  service: 'landlord-portal-frontend',
  env: getEnv().app_env,
  // Specify a version number to identify the deployed version of your application in Datadog
  version,
  sessionSampleRate: 10,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

//look for production url for userpilot
if (window.location.hostname === 'propertymanager.theguarantors.com') {
  Userpilot.initialize(getEnv().userpilot_api_key.replace('STG-', ''));
} else {
  Userpilot.initialize(getEnv().userpilot_api_key);
}

datadogRum.startSessionReplayRecording();

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={materialTheme}>
          <App />
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
