import getFeatureFlags, { FeatureFlags } from '../utils/apiRestCalls/getFeatureFlags';

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

export const initialfeatureFlagValues: FeatureFlags = {
  test: false,
  enable_company_phone_for_smb: false,
  is_new_smb_portfolio_amount: false,
  enable_old_size_of_portfolio: true,
  enable_new_renter_invites_for_smb: true,
  enable_new_deals_dashboard: false,
  enable_new_renters_insurance_dashboard: false,
  enable_footer_trust_center: false,
  enable_footer_marketplace: false,
  enable_footer_blog_rename: false,
  remove_footer_contact_us: false,
  enable_self_enrolled_1_month_SDR_limit: false,
  remove_marketing_meeting_split: false,
  'tiger-1497_ri_enrolled': false,
};

const featureflagStore = create(
  devtools<{
    featureFlags: FeatureFlags;
    useFetchFeatureFlags: () => void;
  }>((set) => ({
    featureFlags: initialfeatureFlagValues,
    useFetchFeatureFlags: () => {
      const { data: featureFlags } = useQuery({
        queryKey: ['featureFlag'],
        queryFn: () => getFeatureFlags(),
        staleTime: Infinity,
      });
      useEffect(() => {
        if (featureFlags) {
          set({ featureFlags: featureFlags });
        }
      }, [featureFlags]);
    },
  })),
);

export default featureflagStore;
